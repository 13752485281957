<template>
    <div :class="show && 'home_matches_show'" class="home_matches">
        <div v-if="game" class="home_matches_body">
            <span class="home-titles">Last Match</span>
            <p class="next_game">{{ game.title }}</p>
            <div class="versus">
                <p class="teams"><img :src="game.homeLogo" alt=""> {{ game.home }} </p>
                <p style="cursor: pointer;" @click="openResult" :id="game.id">{{ game.result }}</p>
                <p class="teams"><img :src="game.awayLogo" alt="">{{ game.away }} </p>
            </div>
            <p class="next_game">{{ game.date }}</p>

            <router-link :to="{ name: 'results' }" class="nd">
                <span class="more_p">Results</span> </router-link>

        </div>
    </div>
</template>

<script>
export default {
    name: 'LastMatchHome',
    inject: ['backendServer', 'currentHost'],
    props: ['show'],
    data() {
        return {
            game: null,
        }
    },
    methods: {
        openResult(event) {
            const width = 500;
            const height = 800;

            // Calculate the left and top positions to center the window
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            const newWindow = window.open(`/team/schedule/results/${event.target.closest('p').id}`, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
            newWindow.opener = null;
        }
    },
    mounted() {

        fetch(this.backendServer() + '/last-game', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.game = data;

            });

    }

}
</script>

<style scoped>
p {
    margin-bottom: 0 !important;
}

.home_matches {
    position: relative;
    overflow: hidden;
}

p.teams img {
    border-radius: 50%;
}

div.versus {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    margin-top: 20px
}


p.teams {
    font-size: .8em;
    flex-basis: 35%;
    display: grid;
    justify-items: center;
}
</style>