<template>
    <div :class="show && 'home_matches_show'" class="home_matches">
        <div v-if="game" class="home_matches_body">
            <span class="home-titles">Next Match</span>
            <p class="next_game">{{ game.title }}</p>
            <div class="versus">
                <p class="teams"><img :src="game.homeLogo" alt=""> {{ game.home }} </p>
                <p>vs</p>
                <p class="teams"><img :src="game.awayLogo" alt="">{{ game.away }} </p>
            </div>
            <p class="next_game">{{ game.date }} {{ game.time }}</p>
            <router-link :to="{ name: 'fixtures' }" class="nd">
                <span class="more_p">Fixtures</span> </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchesHome',
    inject: ['backendServer', 'currentHost'],
    props: ['show'],
    data() {
        return {
            game: null,
        }
    },
    mounted() {

        fetch(this.backendServer() + '/next-game', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.game = data;

            });

    }

}
</script>
<style scoped>
.home_matches {
    position: relative;
    overflow: hidden;
}

p.teams img {
    border-radius: 50%;
}

p {
    margin-bottom: 0 !important;
}

div.versus {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    margin-top: 20px
}


p.teams {
    font-size: .8em;
    flex-basis: 35%;
    display: grid;
    justify-items: center;
}
</style>