<template>

  <div @click="logoChange" :class="logo && 'click'" class="home_logo"></div>
  <main :class="logo && 'visible'" class="home_page">
    <template v-if="!user_login_info">
      <MatchesHome :show="show" />
      <LastMatchHome :show="show" />
      <TableHome :show="show" />
      <MatchdayHome :show="show" />
      <ThreadsHome :show="show" />
      <NewsHome :show="show" />
      <loginHome :show="show" />
      <SignupHome :show="show" />


    </template>
    <template v-else>
      <ProfileHome :show="show" />
      <MatchesHome :show="show" />
      <LastMatchHome :show="show" />
      <TableHome :show="show" />
      <MatchdayHome :show="show" />
      <ThreadsHome :show="show" />
      <NewsHome :show="show" />
    </template>




  </main>
  <footer v-if="show">
    <p>all rights reserved by Rossoneries 2024</p>
  </footer>
</template>

<script>
import MatchesHome from '@/components/home/MatchesHome.vue';
// import ContactHome from '@/components/home/ContactHome.vue';
import LoginHome from '@/components/home/LoginHome.vue';
import SignupHome from '@/components/home/SignupHome.vue';
import ProfileHome from '@/components/home/ProfileHome.vue';
import TableHome from '@/components/home/TableHome.vue';
import NewsHome from '@/components/home/NewsHome.vue';
import LastMatchHome from '@/components/home/LastMatchHome.vue';
import ThreadsHome from '@/components/home/ThreadsHome.vue';
import MatchdayHome from '@/components/home/MatchdayHome.vue';
export default {
  name: "HomeView",
  data() {
    return {
      logo: false,
      show: false
    }
  },
  inheritAttrs: false,
  inject: ['user_login_info'],
  methods: {
    logoChange() {
      this.show = true
      this.logo = true
      document.body.className = ''
    },

  },
  components: { MatchdayHome, NewsHome, ThreadsHome, MatchesHome, LoginHome, SignupHome, ProfileHome, TableHome, LastMatchHome },
  beforeCreate: function () {
    document.body.className = 'homevue'
  },
  mounted() {

    //setTimeout(() => document.querySelector('body').scrollTop = 0, 1000);
    document.querySelector('html').scrollTop = 0;
    document.querySelector('body').scrollTop = 0;
    document.title = 'Home / Rossoneries';

  }



};


// function print(myvar)
// {
//   if (is_production)
//     return;

//       ;
// }
</script>

<style scoped>
footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 12px
}
</style>